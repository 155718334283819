import React from "react";
import "./SideBarWidget.css";
import "./SideBarWidget.mobile.css";
import { faChevronLeft, faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AppConst from "../AppConst";

import { ReactComponent as Instagram } from '../assets/instagram.svg';
import { ReactComponent as Facebook } from '../assets/facebook.svg';
import { ReactComponent as Twitter } from '../assets/twitter.svg';
import { ReactComponent as Youtube } from '../assets/youtube.svg';
import { ReactComponent as Linkedin } from '../assets/linkedin.svg';

export default class SideBarWidget extends React.Component {

    goManual = () => {
        window.open(AppConst.BASE_URL, '_blank');
    }

    render() {
        const { isVisible, toggleSidebar } = this.props;

        return (
            <>
                {/* Only show the overlay if the sidebar is visible */}
                {isVisible && <div className="overlay" onClick={toggleSidebar}></div>}

                {/* Sidebar content */}
                <aside className={`sidebar ${isVisible ? "visible" : ""}`}>
                    {/* Optionally add a button inside the sidebar to close it */}
                    <div className="header">
                        {/* <img src={AppLogo} alt="applogo"></img> */}

                        <button onClick={toggleSidebar}> <FontAwesomeIcon icon={faChevronLeft} /></button>
                    </div>
                    <div className="left-menu">
                        <ul>
                            <li>
                                <a href="/blog/" target="_blank"><span>Blog</span> </a></li>
                            <li><a href="/blog/about-us/" target="_blank"><span>About Us</span> </a></li>
                            <li><a href="/blog/contact-us/" target="_blank"><span>Contact Us</span> </a></li>
                            <li><a href="/blog/partner-with-us/" target="_blank"><span>Partner with Us</span> </a></li>
                        </ul>
                    </div>
                    <div className="social" >
                        <button onClick={() => {
                            window.location.href = 'https://www.linkedin.com/company/courseific/'
                        }}>
                            <Linkedin />
                        </button>
                        <button onClick={() => {
                            window.location.href = '#'
                        }}>
                            <Instagram />
                        </button>
                        <button onClick={() => {
                            window.location.href = '#'
                        }}>
                            <Facebook />
                        </button>
                        <button onClick={() => {
                            window.location.href = '#'
                        }}>
                            <Twitter />
                        </button>
                        <button onClick={() => {
                            window.location.href = '#'
                        }}>
                            <Youtube />
                        </button>

                    </div>
                    <div >
                        <button onClick={this.goManual} className="manual-search">
                            <span>Browse courses</span> <FontAwesomeIcon icon={faExternalLink} ></FontAwesomeIcon>
                        </button>
                    </div>
                </aside>
            </>
        );
    }
}
