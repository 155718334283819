import AppConst from "../AppConst";
import Conversation from "../model/Conversation";
import ConversationRepository from "../repository/ConversationRepository";
import MessageRepository from "../repository/MessageRepository";
import AuthApi from "./AuthApi";

export default class ConversationAPI {

    static async getAll() {
        if (AuthApi.isLoggedIn()) {
            const username = localStorage.getItem('app-username');
            const appPassword = localStorage.getItem('app-password');
            const credentials = btoa(`${username}:${appPassword}`);
            const userId = localStorage.getItem('app-user');

            const response = await fetch(`${AppConst.BASE_URL}/wp-json/wp/v2/conversation?author=${userId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Basic ${credentials}`
                }
            });

            if (response.ok) {
                const jsonData = await response.json();
                return Conversation.fromWordpressAPI(jsonData);
            } else {
                console.log(response.status);
                return [];
            }
        }
    }

    async getConversations(userId) {
        const response = await fetch(`${AppConst.BASE_URL}/wp-json/app/v1/conversations/${userId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching conversations: ${response.statusText}`);
        }

        return response.json();
    }

    async getConversation(conversationId) {
        const response = await fetch(`${AppConst.BASE_URL}/wp-json/app/v1/conversation/${conversationId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching conversation: ${response.statusText}`);
        }

        return response.json();
    }

    /**
     * This function will send to remote database
     * @param {Conversation} conversation 
     * @returns {Promise<Conversation>}
     */
    static async post(conversation) {
        if (AuthApi.isLoggedIn()) {
            const API = conversation.synced === true
                ? `${AppConst.BASE_URL}/wp-json/wp/v2/conversation/${conversation.id}`
                : `${AppConst.BASE_URL}/wp-json/wp/v2/conversation`;
            
            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${AuthApi.getAuthorizationBearer()}`,
                },
                body: conversation.toWordpressJson(),
            });

            if (response.ok) {
                const jsonData = await response.json();
                if (conversation.synced !== true) {
                    // first update the conversation id of the conversation
                    let conversations = ConversationRepository.getConversations();
                    let index = conversations.findIndex((c) => c.id === conversation.id);
                    if (index !== -1) {
                        // Create a copy of the conversation and update the ID
                        let updatedConversation = { ...conversations[index], id: jsonData.id, synced: true };

                        // Replace the old conversation with the updated one in the same index
                        conversations[index] = updatedConversation;
                    }
                    ConversationRepository.saveAll(conversations); // save them all locally

                    // Now time to update messages
                    let messages = MessageRepository.getMessages();
                    messages = messages.map((message) => {
                        if (message.conversationId === conversation.id) {
                            message.conversationId = jsonData.id;
                        }
                        return message;
                    });
                    MessageRepository.saveAll(messages);
                    conversation.id = jsonData.id;
                    conversation.synced = true;

                }
            } else {
                throw new Error(`Error creating/updating conversation: ${response.statusText}`);
            }
            return conversation;
        }
    }


    static async deleteConversation(conversationId) {
        const response = await fetch(`${AppConst.BASE_URL}/wp-json/app/v1/conversation/${conversationId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error deleting conversation: ${response.statusText}`);
        }

        return response.json();
    }
}
