

export default class AppConst {
    static BASE_URL = 'https://www.courseific.com/onlinecourse';
    // static BASE_URL = 'https://stage.ecources.directory'; 
    // static BASE_URL = 'http://localhost:8080'; 

    static APP_NAME = 'Courseific';

    static POST_PUBLISH = 'publish';
    static POST_DRAFT = 'draft';
}