import AppConst from "../AppConst";

class AuthApi {

    static logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('app-user');
        localStorage.removeItem('token-expiry');
    }

    static isLoggedIn() {
        return localStorage.getItem('token') ? true : false;
    }

    static getUserId() {
        return localStorage.getItem('app-user');
    }
    
    static isTokenExpired() {
        const expiryTime = localStorage.getItem('token-expiry');
    
        if (!expiryTime) {
            // If there's no expiry time stored, consider the token expired
            return true;
        }
    
        const now = new Date();
    
        // Compare the current time with the stored expiry time
        return now.getTime() > expiryTime;
    }
    
    static setTokenWithExpiry(token, expiresIn) {
        const now = new Date();
    
        // Calculate the expiration time by adding the expiry time (in seconds) to the current time
        const expiryTime = now.getTime() + expiresIn * 1000;
    
        // Store the token and its expiration time in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('token-expiry', expiryTime);
    }

    static getAuthorizationBearer() {
        const username = localStorage.getItem('app-username');
        const appPassword = localStorage.getItem('app-password');
        const credentials = btoa(`${username}:${appPassword}`);
        return credentials;
    }

    static async login(email, password) {
        try {
            const response = await fetch(AppConst.BASE_URL + '/wp-json/app/v1/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });

            if (!response.ok) {
                throw new Error('Failed to login');
            }

            const data = await response.json();
            localStorage.setItem('app-user', data.user_id);
            localStorage.setItem('app-username', data.username);
            localStorage.setItem('app-email', data.email);
            localStorage.setItem('app-avatar', data.avatar);
            localStorage.setItem('app-first_name', data.first_name);
            localStorage.setItem('app-last_name', data.last_name);
            localStorage.setItem('app-password', data.app_password);
            this.setTokenWithExpiry(data.token, 3600 * 24);
            return data;
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    }

    static async register(username, email, password) {
        try {
            const response = await fetch(AppConst.BASE_URL + '/wp-json/app/v1/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    email: email,
                    password: password
                })
            });

            if (!response.ok) {
                throw new Error('Failed to register');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Registration error:', error);
            throw error;
        }
    }
}

export default AuthApi;
