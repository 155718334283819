import ChatApi from "../api/Api";
import ConversationAPI from "../api/ConversationApi";
import MessageAPI from "../api/MessageApi";
import AppConst from "../AppConst";
import ConversationRepository from "../repository/ConversationRepository";
import Course from "./Course";
import Job from "./Job";

export class MessageSender {
    static ME = "user";
    static ASSISTANT = "assistant";
}

export default class Message {
    constructor(object) {
        this.id = object.id;
        this.conversationId = object.conversationId;
        this.keywords = object.keywords ?? [];
        this.sender = object.sender;
        this.content = object.content;
        this.see_more = object.see_more;
        this.synced = object.synced ?? false;
        this.courses = Course.fromList(object.courses);
        this.jobs = Job.fromList(object.jobs);
        this.requestPrompt = object.requestPrompt;
        this.isLoading = object.isLoading;
        this.status = object.status ?? AppConst.POST_DRAFT;
    }



    toWordpressJson() {
        let jsonData = {};
        if(this.synced === true) {
            jsonData['id'] = this.id;
        }
        jsonData['title'] = this.content;
        jsonData['content'] = JSON.stringify(this.courses);
        jsonData['status'] = this.status;
        jsonData['acf'] = {
            conversationId: this.conversationId,
            see_more: this.see_more,
            keywords: this.keywords.join(','),
        };
        return JSON.stringify(jsonData);
    }

    _onSavedToLocal() {

    }

    async send() {
        if(this.sender === MessageSender.ME) {
            // save the message locally
            if(this._onSavedToLocal()) {
                let prompt = this.content;
                await ChatApi.search(prompt, ConversationRepository.getConversation(this.conversationId));
                // send an API request to save the conversation to remote server
                let message = await MessageAPI.post(this);
                this.id = message.id;
                this._updateLocal();
            }

        }

        // 2: update this message
    }

    getCategories() {
        const categoriesSet = new Set(); // Use a Set to keep unique categories

        this.courses.forEach(course => {
            Object.entries(course.categories).forEach(([url, category]) => {
                // Create a unique identifier for each category
                const uniqueCategory = `${url}|${category}`;
                // Add to the Set (duplicates will be automatically ignored)
                categoriesSet.add(uniqueCategory);
            });
        })


        // Convert the Set back to an array of objects
        const categories = Array.from(categoriesSet).map(categoryString => {
            const [url, category] = categoryString.split('|');
            return { url: url, name: category };
        });

        return categories;
    }

    /**
    *
    * @param {Array} objectList
    * @returns {Message[]}
    */
    static fromList(objectList = []) {
        if (objectList.length === 0)
            return objectList;
        return objectList.map(o => new Message(o));
    }

}