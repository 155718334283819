

export default class Job {
    constructor(object) {
        this.name = object.name;
        this.url = object.url;
    }


    /**
   *
   * @param {Array} objectList
   * @returns {Job[]}
   */
    static fromList(objectList = []) {
        if (objectList.length === 0)
            return objectList;
        return objectList.map(o => new Job(o));
    }
}