import React from "react";
import "./CategoriesWidget.css";

export default class CategoriesWidget extends React.Component {
    onCategoryClicked = (e, category) => {
        e.preventDefault();
        this.props.triggerSearch("I'm looking to learn " + category.name, this.props.conversation.getMessages());
        // this.props.scrollToBottom();
    }

    render() {
        const {categories} = this.props;

        return <>
            {categories.length > 0 ? <>
                <div className="course-categories">
                    <h4>Related categories</h4>
                    <div className="list">
                        {categories.length > 0 ? categories.map((course, index) => (
                            <a key={index} rel="norefferer" onClick={(e) => this.onCategoryClicked(e, course)} className="category" href={course.url} dangerouslySetInnerHTML={{ __html: course.name }}></a>
                        )) : <></>}
                    </div>
                </div>
            </> : <></>}</>
    }
}